@font-face {
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 400;
	src: url('Fonts/nunito-v25-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('Fonts/nunito-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('Fonts/nunito-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		url('Fonts/nunito-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
		url('Fonts/nunito-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		url('Fonts/nunito-v25-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
